import AutoRenewIcon from '@mui/icons-material/Autorenew';

import { Subscriptions } from '@/appTypes/Subscription';
import { useAppNavigate, useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { OrderGtmEvent, trackEvent, trackGTMEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { HomeBox } from './HomeBox';

export const RenewPlanBox = () => {
  const { t } = useAppTranslation();
  const { navigate } = useAppNavigate();
  const currentUser = useAppSelector((state) => state.user.currentUser);

  const { previousPlan, subscription: currentPlan } = currentUser || {};

  if (!currentPlan || currentPlan.type !== Subscriptions.FREE || !previousPlan) return null;

  const openOrderSummaryWithSelectedPlan = () => {
    if (!previousPlan) return;

    trackGTMEvent(OrderGtmEvent.renewFromHome);
    trackEvent.ecommerce.renewPlan();

    navigate(Pages.userSubscriptionSummary, {}, { plan: previousPlan.type });
  };

  return (
    <HomeBox
      title={t('home:yourPlanHasBeenExpired', { planName: previousPlan.type })}
      message={t('home:yourPlanHasBeenExpiredDescription', { planName: previousPlan.type })}
      buttonLabel={t('common:renewPlan')}
      onClick={openOrderSummaryWithSelectedPlan}
      surfaceVariant="secondaryContainer"
      buttonIcon={<AutoRenewIcon />}
    />
  );
};
