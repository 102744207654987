import { Box, Button, Stack } from '@mui/material';

import { Text } from '@/components/elements';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { BetaBadge } from './BetaBadge';
import { ChatIcon } from './ChatIcon';

interface Props {
  hideHistoryButton?: boolean;
  hideDescription?: boolean;
  shortTitle?: boolean;
}

export const AssistantChatHeader = ({ shortTitle, hideHistoryButton, hideDescription }: Props) => {
  const { t } = useAppTranslation();

  const titleKey = shortTitle ? 'chat:assistant' : 'chat:talkWithAssistant';

  return (
    <Box display="flex" flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between">
      <Stack spacing={1} flex={1} mb={[2, 0]}>
        <Stack direction="row" spacing={4} alignItems="center">
          {!shortTitle && <ChatIcon />}
          <Text variant="headlineSmall" translationKey={titleKey} />
          <BetaBadge />
        </Stack>
        {!hideDescription && <Text variant="bodyMedium" translationKey="chat:talkWithAssistantDescription" />}
      </Stack>

      {!hideHistoryButton && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button variant="text" color="primary" href={Pages.assistantChat}>
            {t('chat:chatHistory')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
