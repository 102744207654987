import { Button, styled } from '@mui/material';

export const ProfileDropdownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.app.primary,
  borderColor: theme.palette.app.outline,
  borderRadius: 100,
  padding: '10px 16px 10px 24px',
  textTransform: 'none',
}));

export const HeaderBar = styled('header')({
  padding: 0,
  paddingBottom: 3,
  height: 100,
  marginLeft: -8,
  marginRight: -8,
});
