import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Suspense, lazy } from 'react';

import { Pages } from '@/navigation';

import { ConditionalWrapper } from './ConditionalWrapper';

const BlackLogo = lazy(() => import('@/assets/logo/logo-horizontal-black.svg'));
const WhiteLogo = lazy(() => import('@/assets/logo/logo-horizontal-white.svg'));

interface LogoProps {
  linkToHome?: boolean;
  linkToLandingPage?: boolean;
}

export const Logo = ({ linkToHome, linkToLandingPage }: LogoProps) => {
  const { palette } = useTheme();
  const isLightMode = palette.mode === 'light';

  return (
    <ConditionalWrapper
      condition={!!linkToHome || !!linkToLandingPage}
      wrapper={(children) => (
        <Link display="block" href={linkToHome ? Pages.home : Pages.landing}>
          {children}
        </Link>
      )}
    >
      <Suspense>
        {isLightMode ? (
          <BlackLogo height="100px" width="fit-content" />
        ) : (
          <WhiteLogo height="100px" width="fit-content" />
        )}
      </Suspense>
    </ConditionalWrapper>
  );
};
