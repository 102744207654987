import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useDeviceSize = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
