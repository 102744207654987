import { Surface } from '@/components/layout';
import { AssistantChatHeader } from '@/features/Chat/components';
import { AssistantChat } from '@/features/Chat/components/AssistantChat';

export const AssistantChatBox = () => {
  return (
    <Surface sx={{ p: 1 }} spacing={4}>
      <AssistantChatHeader hideDescription />
      <AssistantChat encouragingMessageKey="chat:encouragingMessages.homeAndChatPage" showInstructions />
    </Surface>
  );
};
