import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { Page } from '@/components/layout';
import { useRefreshCurrentUserData } from '@/features/User';

import {
  ResumeQuiz,
  QuizCreator,
  RenewPlanBox,
  ReviewMistakesBox,
  ExpiringPlanBox,
  AssistantChatBox,
} from '../../components';

export const HomePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();

  useEffect(() => {
    refreshCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Stack spacing={8}>
        {/* Plan sections */}
        <Stack spacing={4}>
          <RenewPlanBox />
          <ExpiringPlanBox />
        </Stack>

        {/* Assistant chat sections */}
        <AssistantChatBox />

        {/* Quiz sections */}
        <Stack spacing={4}>
          <ResumeQuiz />
          <ReviewMistakesBox />
          <QuizCreator />
        </Stack>
      </Stack>
    </Page>
  );
};
