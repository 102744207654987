import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Subscriptions } from '@/appTypes/Subscription';
import { DataWithLabel, Text } from '@/components/elements';
import { ConfirmationModal } from '@/components/elements/ConfirmationModal';
import { Meta, Page, Surface } from '@/components/layout';
import { planFullName, planColor, formatExpirationDate } from '@/features/Subscription/utils';
import { useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { UserProfileLanguageSelector } from '../../components';
import { useRemoveAccount, useRefreshCurrentUserData } from '../../hooks';

import { ChangePasswordForm } from './ChangePasswordForm';
import { UserDataForm } from './UserDataForm';

export const UserProfilePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { currentUser } = useAppSelector((state) => state.user);
  const { t } = useAppTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const { isLoading: isLoadingRemovingAccount, removeAccount } = useRemoveAccount();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    refreshCurrentUserData();
  }, [refreshCurrentUserData]);

  const currentPlan = currentUser?.subscription?.type || Subscriptions.FREE;
  const currentPlanName = t(planFullName[currentPlan]) as TranslationKeys;

  const expirationDate = formatExpirationDate(currentUser?.subscription?.expirationDate);

  const showCookiesConsent = () => {
    setSearchParams({ showCookiesSettings: 'true' });
  };

  const planButtonVariant = currentPlan === Subscriptions.FREE ? 'contained' : 'text';

  return (
    <Page showBackButton>
      <Meta titleKey="common:profile" />
      <Surface>
        <Stack rowGap={10} columnGap={10}>
          <Stack rowGap={16} columnGap={16} direction="row" flexWrap="wrap">
            <UserDataForm />
            <ChangePasswordForm />
            <Stack spacing={4}>
              <Text variant="titleLarge" translationKey="user:subscription" />
              <DataWithLabel
                labelKey="user:currentPlan"
                data={currentPlanName}
                dataColor={planColor[currentUser?.subscription?.type || Subscriptions.FREE]}
              />
              {expirationDate && <DataWithLabel labelKey="user:expirationDate" data={expirationDate} />}
              <Button variant={planButtonVariant} color="primary" href={Pages.userSubscriptionSelect}>
                {t('user:switchToAnotherPlan')}
              </Button>
            </Stack>
          </Stack>
          <UserProfileLanguageSelector />
        </Stack>
        <Stack direction="row" justifyContent="center" mt={16} spacing={6}>
          <Button variant="outlined" onClick={showCookiesConsent}>
            {t('common:cookiesFilesSettings')}
          </Button>
          <Button variant="outlined" color="error" onClick={() => setIsModalOpen(true)}>
            {t('user:removeAccount')}
          </Button>
        </Stack>
      </Surface>

      <ConfirmationModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={removeAccount}
        title={t('user:removingAccount')}
        description={t('user:removingAccountDescription')}
        dangerous
        loading={isLoadingRemovingAccount}
      />
    </Page>
  );
};
