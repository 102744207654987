import { GTMProvider, sendToGTM } from '@elgorditosalsero/react-gtm-hook';

import { googleTagManagerId } from '@/config/env';

type GTMConfig = React.ComponentProps<typeof GTMProvider>['state'];

export const getGoogleTagManagerConfig = (): GTMConfig => {
  if (!googleTagManagerId) return;

  return {
    id: googleTagManagerId,
  };
};

interface PushDataLayerProps {
  event: string;
  name: string;
  data?: object;
}

const gtag = (...data: unknown[]) => {
  window.gtag(...data);
};

export const gtagEvent = ({ event, name, data }: PushDataLayerProps) => {
  if (!googleTagManagerId) return;

  gtag(event, name, data);
};

const isGTMInitialized = () => {
  return window.dataLayer && Array.isArray(window.dataLayer);
};

const createDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
};

interface GTMEventProps {
  event: string;
  data: object;
}

export const gtmEvent = ({ event, data }: GTMEventProps) => {
  if (!googleTagManagerId) return;

  if (!isGTMInitialized()) createDataLayer();

  sendToGTM({
    dataLayerName: 'dataLayer',
    data: {
      event,
      ...data,
    },
  });
};
