import { RootState } from './store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Migration = (state: any) => any;
type Migrations = Record<number, Migration>;

export const storeMigrations: Migrations = {
  0: (state: RootState) => state,

  // 1: Add new `chat.availableThreads` field to user.currentUser.subInfo if it doesn't exist and rest of the objects exists
  1: (state: RootState) => {
    const currentUser = state.user.currentUser;
    if (!currentUser) return state;

    const subInfo = currentUser.subInfo;
    if (!subInfo) return state;

    return {
      ...state,
      user: {
        ...state.user,
        currentUser: {
          ...currentUser,
          subInfo: {
            ...subInfo,
            chat: {
              availableThreads: subInfo.chat?.availableThreads || 0,
            },
          },
        },
      },
    };
  },
};
