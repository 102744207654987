import { Box, Container } from '@mui/material';

import { BackToHomePageButton, Header } from '@/components/elements';

interface Props {
  children: React.ReactNode;
  overflowVisible?: boolean;
  showBackButton?: boolean;
  removeBottomPadding?: boolean;
}

export const Page = ({ children, overflowVisible, showBackButton, removeBottomPadding }: Props) => {
  return (
    <Box flex={1} width="100%" overflow={overflowVisible ? 'visible' : 'auto'}>
      <Container maxWidth="md" sx={{ mt: 4, px: 3, pb: removeBottomPadding ? 0 : 10 }}>
        <Header />

        {showBackButton && (
          <Box mb={2}>
            <BackToHomePageButton />
          </Box>
        )}

        {children}
      </Container>
    </Box>
  );
};
