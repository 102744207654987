import SchoolIcon from '@mui/icons-material/School';
import { Box, Button, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Text } from '@/components/elements';
import { ControlledButton } from '@/components/form';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { useCreateQuizForm } from '../../hooks/useCreateQuizForm';

import { CreatorOptionContainer } from './CreatorOptionContainer';
import { SelectableArticles } from './SelectableArticles';
import { SelectableModeOfQuiz } from './SelectableModeOfQuiz';
import { SelectableNumberOfQuestions } from './SelectableNumberOfQuestions';

export const QuizCreator = () => {
  const { t } = useAppTranslation();

  const { form, onSubmitPress, isSubmitting } = useCreateQuizForm();

  const disableForm = isSubmitting;

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={8} pt={[2, 4]}>
        <Stack spacing={4} direction="row" alignItems="center">
          <SchoolIcon sx={{ fontSize: 28 }} color="primary" />
          <Text variant="headlineMedium">{t('home:createNewQuiz')}</Text>
        </Stack>
        <Button variant="text" color="primary" href={Pages.history}>
          {t('quiz:quizHistory')}
        </Button>
      </Box>

      <Stack spacing={8} data-test="quizCreator" sx={{ px: [4, 6], pb: [4, 6], pt: [2, 4] }}>
        <CreatorOptionContainer title={t('home:selectArticles')}>
          <SelectableArticles disabled={disableForm} />
        </CreatorOptionContainer>

        <CreatorOptionContainer title={t('home:numberOfQuestions')}>
          <SelectableNumberOfQuestions disabled={disableForm} />
        </CreatorOptionContainer>

        <CreatorOptionContainer title={t('home:modeOfQuiz')}>
          <SelectableModeOfQuiz disabled={disableForm} />
        </CreatorOptionContainer>

        <Box textAlign="center">
          <ControlledButton color="primary" variant="contained" onClick={onSubmitPress} disabled={disableForm}>
            {t('common:startNewQuiz')}
          </ControlledButton>
        </Box>
      </Stack>
    </FormProvider>
  );
};
