import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import {
  IconButton,
  MenuItem,
  Menu,
  Box,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';

import { useLogout } from '@/features/User';
import { useAppDispatch, useAppNavigate, useAppSelector, useIsPage } from '@/hooks';
import { Pages } from '@/navigation';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { useAppTranslation } from '@/translation';

import { Logo } from '../Logo';

import { HeaderBar, ProfileDropdownButton } from './styled';

const menuId = 'header-user-menu';

export const Header = () => {
  const { t } = useAppTranslation();
  const { palette, breakpoints } = useTheme();
  const { currentUser } = useAppSelector((state) => state.user);
  const { navigate } = useAppNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const hideMenu = !currentUser;

  const { logout } = useLogout();

  const isDarkMode = palette.mode === 'dark';
  const isMediumScreen = useMediaQuery(breakpoints.up('sm'));
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const isQuizzesPage = useIsPage([Pages.home, Pages.quiz, Pages.quizSummary]);
  const isAssistantChatPage = useIsPage(Pages.assistantChat);
  const quizzesButtonColor = isQuizzesPage ? 'secondary' : 'inherit';
  const assistantChatButtonColor = isAssistantChatPage ? 'secondary' : 'inherit';

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    handleMenuClose();
    logout();
  }, [logout]);

  const toggleThemeMode = useCallback(() => {
    dispatch(toggleThemeModeAction());
  }, [dispatch]);

  const renderMenu = useMemo(
    () => (
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            padding: '0 !important',
          },
        }}
      >
        {!isLargeScreen && !isMediumScreen && (
          <>
            {!isQuizzesPage && (
              <MenuItem onClick={() => navigate(Pages.home)} data-test="header_user-menu_home">
                <ListItemIcon>
                  <SchoolIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('common:quizzes')}</ListItemText>
              </MenuItem>
            )}

            {!isAssistantChatPage && (
              <MenuItem
                onClick={() => navigate(Pages.assistantChat)}
                data-test="header_user-menu_assistant_chat"
                disabled={isAssistantChatPage}
              >
                <ListItemIcon>
                  <AutoAwesomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('chat:assistantChat')}</ListItemText>
              </MenuItem>
            )}

            <Divider />
          </>
        )}

        <MenuItem onClick={() => navigate(Pages.userProfile)} data-test="header_user-menu_profile">
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common:profile')}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => navigate(Pages.history)} data-test="header_user-menu_history">
          <ListItemIcon>
            <HistoryOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common:quizzesHistory')}</ListItemText>
        </MenuItem>

        {!isLargeScreen && (
          <MenuItem onClick={toggleThemeMode}>
            <ListItemIcon>
              <LightModeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t(isDarkMode ? 'common:lightMode' : 'common:darkMode')}</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout} data-test="header_user-menu_logout">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('user:logout')}</ListItemText>
        </MenuItem>
      </Menu>
    ),
    [
      anchorEl,
      handleLogout,
      isAssistantChatPage,
      isDarkMode,
      isLargeScreen,
      isMediumScreen,
      isMenuOpen,
      isQuizzesPage,
      navigate,
      t,
      toggleThemeMode,
    ],
  );

  return (
    <>
      <HeaderBar>
        <Stack direction="row" justifyContent="space-between">
          <Logo linkToHome />

          <Box display={hideMenu ? 'none' : 'flex'} alignItems="center" paddingRight={4}>
            {isMediumScreen && (
              <>
                <Button variant="text" startIcon={<SchoolIcon />} color={quizzesButtonColor} href={Pages.home}>
                  {t('common:quizzes')}
                </Button>
                <Button
                  variant="text"
                  startIcon={<AutoAwesomeIcon />}
                  color={assistantChatButtonColor}
                  href={Pages.assistantChat}
                >
                  {t('chat:assistantChat')}
                </Button>
              </>
            )}

            {isLargeScreen ? (
              <>
                <IconButton
                  onClick={toggleThemeMode}
                  size="large"
                  aria-label="toggle theme mode"
                  sx={{ mr: 2, color: palette.app.outline }}
                >
                  <LightModeIcon />
                </IconButton>
                <ProfileDropdownButton
                  variant="outlined"
                  onClick={handleMenuOpen}
                  aria-controls={menuId}
                  aria-label="menu"
                  endIcon={isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  data-test="header_user-menu"
                >
                  {t('user:helloName', { name: currentUser?.firstName })}
                </ProfileDropdownButton>
              </>
            ) : (
              <IconButton
                onClick={handleMenuOpen}
                aria-controls={menuId}
                aria-label="menu"
                sx={{ color: palette.app.outline }}
                data-test="header_user-menu"
              >
                <MenuIcon sx={{ height: 40, width: 'auto' }} />
              </IconButton>
            )}
          </Box>
        </Stack>
      </HeaderBar>
      {renderMenu}
    </>
  );
};
