import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Box, Container, IconButton, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useState } from 'react';

import { Text } from '@/components/elements';
import { Logo } from '@/components/elements/Logo';
import { useAppDispatch } from '@/hooks';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { TranslationKeys } from '@/translation';

import { LanguageSelector } from './LanguageSelector';

interface LoginPageTemplateProps {
  children: ReactNode;
  headerMessageKey?: TranslationKeys;
}

export const LoginPageTemplate = ({ children, headerMessageKey }: LoginPageTemplateProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [shouldOpenLangSel, setShouldOpenLangSel] = useState(false);

  const toggleThemeMode = () => dispatch(toggleThemeModeAction());

  return (
    <Container maxWidth="xs" sx={{ height: '100vh' }}>
      <Box alignItems="center" justifyContent="center" py={8}>
        <Logo linkToLandingPage />
        <Paper elevation={5} sx={{ p: [4, 6, 8], mb: 2, mt: 1, width: '100%' }}>
          {headerMessageKey && (
            <Box textAlign="center" mb={8}>
              <Text variant="headlineMedium" translationKey={headerMessageKey} />
            </Box>
          )}
          {children}
        </Paper>

        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" ml={2} alignItems="center">
            <IconButton onClick={() => setShouldOpenLangSel(true)}>
              <LanguageIcon fontSize="small" />
            </IconButton>
            <LanguageSelector
              variant="standard"
              shouldOpen={shouldOpenLangSel}
              onOpen={() => setShouldOpenLangSel(false)}
            />
          </Stack>
          <IconButton
            onClick={toggleThemeMode}
            size="large"
            aria-label="toggle theme mode"
            sx={{ mr: 2, color: palette.app.outline }}
          >
            <LightModeIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
};
