import { matchRoutes, useLocation } from 'react-router-dom';

import { Pages } from '@/navigation';

const routes = Object.values(Pages).map((path) => ({ path }));

export const useGetCurrentPageName = () => {
  const location = useLocation();

  const getCurrentPageName = (): Pages | null => {
    const data = matchRoutes(routes, location)?.find((m) => m.pathname === location.pathname);

    return (data?.route.path as Pages) || null;
  };

  const currentPageName = getCurrentPageName();

  return currentPageName;
};
