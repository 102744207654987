import AutoRenewIcon from '@mui/icons-material/Autorenew';
import dayjs from 'dayjs';

import { Subscriptions } from '@/appTypes/Subscription';
import { useAppNavigate, useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { OrderGtmEvent, trackEvent, trackGTMEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { HomeBox } from './HomeBox';

export const ExpiringPlanBox = () => {
  const { t } = useAppTranslation();
  const { navigate } = useAppNavigate();
  const currentSubscription = useAppSelector((state) => state.user.currentUser?.subscription);

  const expirationDate = dayjs(currentSubscription?.expirationDate);
  const daysToExpire = expirationDate.diff(dayjs(), 'day');
  const hoursToExpire = expirationDate.diff(dayjs(), 'hour');
  const isAboutToExpire = dayjs().isBefore(expirationDate);
  const currentPlan = currentSubscription?.type;

  if (!currentPlan || currentPlan === Subscriptions.FREE || !isAboutToExpire || daysToExpire > 5) return null;

  const openOrderSummaryWithSelectedPlan = () => {
    if (!currentPlan) return;

    trackGTMEvent(OrderGtmEvent.extendFromHome);
    trackEvent.ecommerce.extendPlan();

    navigate(Pages.userSubscriptionSummary, {}, { plan: currentPlan });
  };

  const title =
    daysToExpire <= 1
      ? t('home:yourPlanIsAboutToExpireInHours', { planName: currentPlan, count: hoursToExpire })
      : t('home:yourPlanIsAboutToExpireInDays', { planName: currentPlan, count: daysToExpire });

  return (
    <HomeBox
      title={title}
      message={t('home:toNotLooseAccessToPlanFeaturesRenewYourPlan', {
        planName: currentPlan,
      })}
      buttonLabel={t('common:extendPlan')}
      onClick={openOrderSummaryWithSelectedPlan}
      surfaceVariant="secondaryContainer"
      buttonIcon={<AutoRenewIcon />}
    />
  );
};
