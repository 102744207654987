import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, IconButton, Modal, Paper } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';

import { useDeviceSize } from '@/hooks';

interface ModalTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  maxWidth?: number;
  scrollable?: boolean;
}

const AnimatedPaper = motion(Paper);

export const ModalTemplate = ({ isOpen, onClose, children, maxWidth = 400, scrollable }: ModalTemplateProps) => {
  const { isMobile } = useDeviceSize();

  const handleOnClose = (_event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          transitionDuration: 300,
        },
      }}
    >
      <div>
        <AnimatePresence mode="wait">
          <AnimatedPaper
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            sx={{
              maxWidth,
              maxHeight: isMobile ? '100dvh' : '90dvh',
              overflow: scrollable ? 'auto' : 'hidden',
              borderRadius: isMobile ? 0 : undefined,
              position: 'relative',
            }}
          >
            <Box position="absolute" top={[8, 12]} right={[8, 12]}>
              <IconButton onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
            {children}
          </AnimatedPaper>
        </AnimatePresence>
      </div>
    </Modal>
  );
};
