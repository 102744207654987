import { Box, Stack, TextField } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Text } from '@/components/elements';
import { ControlledButton, ControlledTextField } from '@/components/form';
import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { useUserProfileForm } from '../../hooks';

export const UserDataForm = () => {
  const { t } = useAppTranslation();
  const user = useAppSelector((state) => state.user.currentUser);
  const { form, onSubmitPress, isFormEdited } = useUserProfileForm();

  return (
    <Stack spacing={8} flex={1}>
      <Text variant="titleLarge">{t('user:yourAccount')}</Text>
      <FormProvider {...form}>
        <Stack spacing={6} maxWidth={450}>
          <TextField
            name="email"
            label={t('user:emailAddress')}
            value={user?.email}
            fullWidth
            size="small"
            disabled
            aria-readonly
          />
          <Stack direction="row" spacing={6} alignItems="center">
            <ControlledTextField
              name="firstName"
              label={t('user:firstName')}
              autoComplete="given-name"
              fullWidth
              size="small"
              alwaysShowLabel
            />
            <ControlledTextField
              id="lastName"
              name="lastName"
              label={t('user:lastName')}
              autoComplete="family-name"
              fullWidth
              size="small"
              alwaysShowLabel
            />
          </Stack>
          <Box justifyContent="center" display="flex" flex={1}>
            <ControlledButton type="submit" variant={isFormEdited ? 'contained' : 'outlined'} onClick={onSubmitPress}>
              {t('user:updateData')}
            </ControlledButton>
          </Box>
        </Stack>
      </FormProvider>
    </Stack>
  );
};
