export const googleTagManagerId = import.meta.env.VITE_GTM_ID;
export const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
export const apiUrl = import.meta.env.VITE_API_URL;
export const appEnvironment = import.meta.env.VITE_APP_ENVIRONMENT;
export const googleOAuthClientId = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID;
export const stripeKey = import.meta.env.VITE_STRIPE_KEY;

export const isDev = import.meta.env.DEV || appEnvironment === 'development';
export const isStaging = appEnvironment === 'staging';
export const isProd = appEnvironment === 'production';
