import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isDev } from '@/config';

import { useSSOLogin } from '../../hooks';

import { SSOButton } from './SSOButton';

interface SSOLoginProps {
  type: 'login' | 'registration';
  onStateChange: (isLoading: boolean) => void;
  disabled: boolean;
}

export const SSOLogin = ({ type, onStateChange, disabled }: SSOLoginProps) => {
  // const [isFacebookReady, setIsFacebookReady] = useState(!!window.FB);

  const { loginUsingGoogle, isLoading } = useSSOLogin();

  useEffect(() => {
    onStateChange(isLoading);
  }, [isLoading, onStateChange]);

  // useEffect(() => {
  //   if (!isFacebookReady) {
  //     const interval = setInterval(() => {
  //       if (isFBReady()) {
  //         setIsFacebookReady(true);
  //         clearInterval(interval);
  //       }
  //     }, 100);
  //   }
  // });

  // const isFBReady = () => {
  //   return !!window.FB;
  // };

  return (
    <Box justifyContent="center" alignContent="center" alignItems="center" width="100%" maxWidth={300}>
      {/* Source: https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid#get_your_google_api_client_id */}
      {isDev && (
        <Helmet>
          <meta name="referrer" content="no-referrer-when-downgrade" />
        </Helmet>
      )}
      <Stack spacing={4}>
        <SSOButton type={type} onClick={loginUsingGoogle} disabled={isLoading || disabled} />
        {/* <Box display={!isFacebookReady ? 'none' : 'block'}>
          <FacebookLogin
            appId={googleOAuthAppId}
            onSuccess={onFacebookSuccess}
            onFail={onFacebookFail}
            fields=""
            scope=""
            render={({ onClick }) => (
              <SSOButton provider="facebook" type={type} onClick={onClick} disabled={isLoading || disabled} />
            )}
          />
        </Box> */}
      </Stack>
    </Box>
  );
};
