import loadable from '@loadable/component';

export const LazyQuizPage = loadable(() => import(/* webpackPrefetch: true */ '@/features/Quiz/routes/QuizPage'), {
  resolveComponent: (components) => components.QuizPage,
});

export const LazyQuizSummaryPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/Quiz/routes/QuizSummaryPage'),
  {
    resolveComponent: (components) => components.QuizSummaryPage,
  },
);

export const LazyHistoryPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/Quiz/routes/HistoryPage'),
  {
    resolveComponent: (components) => components.HistoryPage,
  },
);

export const LazyDemoPage = loadable(() => import(/* webpackPrefetch: true */ '@/features/Quiz/routes/QuizDemoPage'), {
  resolveComponent: (components) => components.QuizDemoPage,
});

export const LazySubscriptionSelectPage = loadable(() => import('@/features/Subscription'), {
  resolveComponent: (components) => components.SubscriptionSelectPage,
});

export const LazySubscriptionSummaryPage = loadable(() => import('@/features/Subscription'), {
  resolveComponent: (components) => components.SubscriptionSummaryPage,
});

export const LazySubscriptionPaymentStatusPage = loadable(() => import('@/features/Subscription'), {
  resolveComponent: (components) => components.SubscriptionPaymentStatusPage,
});
export const LazyAssistantChatPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/Chat/routes/AssistantChatPage/AssistantChatPage'),
  {
    resolveComponent: (components) => components.AssistantChatPage,
  },
);
