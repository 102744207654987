import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

import { useAppDispatch, useNotify } from '@/hooks';
import { captureError, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { ssoLogin } from '../api';
import { setSession } from '../store';

export const useSSOLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const catchLoginError = (error: unknown, type: 'google' | 'facebook') => {
    notify('error', t('user:ssoLoginFailed'));
    captureError(new Error(`${type} SSO login failed:` + JSON.stringify(error)));
  };

  const loginUsingGoogleRequest = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      if (!access_token) {
        notify('error', t('user:ssoLoginFailed'));
        captureError(new Error('Google SSO login failed'));
        setIsLoading(false);
        return;
      }

      const { accessToken, refreshToken } = await ssoLogin({
        type: 'google',
        payload: {
          accessToken: access_token,
        },
      });
      trackEvent.user.login('google');
      dispatch(setSession({ accessToken, refreshToken }));
      setIsLoading(false);
    },
    error_callback: (error) => {
      setIsLoading(false);
      if (error.type === 'popup_closed') return;

      catchLoginError(error, 'google');
    },
    onError: (error) => {
      setIsLoading(false);
      catchLoginError(error, 'google');
    },
  });

  const loginUsingGoogle = () => {
    setIsLoading(true);
    loginUsingGoogleRequest();
  };

  // const onFacebookSuccess = async (response: SuccessResponse) => {
  //   setIsLoading(true);
  //   const facebookAccessToken = response.accessToken;
  //   if (!facebookAccessToken) {
  //     notify('error', t('user:ssoLoginFailed'));
  //     captureError(new Error('Facebook SSO login failed: Lack of access token'));
  //     return;
  //   }

  //   const { accessToken, refreshToken } = await ssoLogin({
  //     type: 'facebook',
  //     payload: {
  //       accessToken: facebookAccessToken,
  //     },
  //   });

  //   setSessionAndRedirectUser(accessToken, refreshToken);
  //   setIsLoading(false);
  // };

  // const onFacebookFail = (error: FailResponse) => catchLoginError(error, 'facebook');

  return {
    loginUsingGoogle,
    isLoading,
  };
};
