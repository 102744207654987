import { User, UserFromBE } from '@/appTypes/User';

export const processUserBeToFe = (userFromBE: UserFromBE): User => {
  return {
    firstName: userFromBE.name,
    lastName: userFromBE.surname,
    email: userFromBE.username,
    meta: {
      unreviewedMistakesCount: userFromBE.unreviewedMistakesCount,
    },
    ...userFromBE,
    subInfo: !userFromBE.subInfo
      ? null
      : {
          ...userFromBE.subInfo,
          chat: {
            availableThreads: userFromBE.subscription?.availableChatbotThreads || 0,
          },
        },
  };
};
